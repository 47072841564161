import {useDispatch} from "react-redux";
import React, {useEffect, useState} from "react";
import Modal from "react-modal";
import {editPriceItem} from "../../../../reducers/dataReducer";
import validator from "validator";

export function EditPriceItem(props){
    let edited_price_item = props.price_item;

    const dispatch = useDispatch();
    const [showModal, setModal] = useState(false);

    const [itemId, setItemId] = useState(edited_price_item.id);
    const [itemName, setItemName] = useState(edited_price_item.name);
    const [itemPrice, setItemPrice] = useState(edited_price_item.price);



     //токен
    const [token, setToken] = useState("");
    useEffect(() => {
        const token = JSON.parse(localStorage.getItem('token'));
        if (token) {
            setToken(token);
        }
    }, []);
    //__/токен


    let EditPriceItem = async (e) => {
        e.preventDefault();
        const requestOptions = {
            method: "PATCH",
            headers: {
                "accept": "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
            body: JSON.stringify({
                "name": itemName,
                "price": itemPrice,
                "section": edited_price_item.section.toString(),
            }),
        };
        const response = await fetch(`https://api.xn---24-eddkgafeegidb2egfb1b.xn--p1ai/price/edit_item?item_id=${itemId}`, requestOptions);
        const data = await response.json();
        if (!response.ok) {
            console.log("Хуйня");
        } else {
            dispatch(editPriceItem(data));
        }
        setModal(false);
    };

    return(
        <div className={"button-edit-itemprice"}>
            <button onClick={() => setModal(true)}>Изменить</button>
            <Modal isOpen={showModal} contentLabel="Форма: изменить">
                <h2>Редактировать позицию</h2>
                <form>
                    <label>
                        <span>Название</span>
                        <input
                            name="price-item-name"
                            type="text"
                            value={itemName}
                            onChange={(event) => setItemName(event.target.value)}
                        />
                    </label>
                    <br/>
                    <label>
                        <span>Цена</span>
                        <input
                            name="price-item-price"
                            type="text"
                            value={itemPrice}
                            onChange={(event) => setItemPrice(event.target.value)}
                        />
                    </label>
                    <br/>

                    <button
                        onClick={EditPriceItem}
                        className="editButton"
                    >
                        Изменить
                    </button>
                </form>
            </Modal>
        </div>
    );
}