import './News.sass';
import {useSelector} from "react-redux";
import {deleteNews, newsList} from "../../../../reducers/dataReducer";
import {AddNewsForm} from "./AddNewsForm";
import {useDispatch} from "react-redux";
import {useEffect, useState} from "react";
import {EditNewsForm} from "./EditNewsForm";

export function News() {
    const dispatch = useDispatch();
    let newss = useSelector(newsList);

    const[deletedNews, setDel] = useState(0);

    //токен
    const [token, setToken] = useState("");
    useEffect(() => {
        const token = JSON.parse(localStorage.getItem('token'));
        if (token) {
            setToken(token);
        }
    }, []);
    //__/токен

    let DeleteNews = async (e) => {
        if(window.confirm('Удалить?')) {
            e.preventDefault();
            const requestOptions = {
                method: "DELETE",
                headers: {
                    "accept": "application/json",
                    Authorization: "Bearer " + token,
                },

            };
            const response = await fetch(`https://api.xn---24-eddkgafeegidb2egfb1b.xn--p1ai/news/delete?news_id=${deletedNews}`, requestOptions);
            const data = await response.json();
            if (!response.ok) {
                console.log("Хуйня");
            } else {
                dispatch(deleteNews(deletedNews));
                alert(data);
            }
        }
    };


    return (
        <div className="newsall">
            <h2>Новости</h2>
            {newss.map((news) => {
                return (
                    <div className={"news-item"} key={news.news_id}>
                        <div className={"news-image"}>
                            {news.news_img}
                        </div>
                        <div className={"news-header"}>
                            {news.header}
                        </div>
                        <div className={"news-short-descr"}>
                            {news.short_descr}
                        </div>
                        <div className={"news-description"}>
                            {news.description}
                        </div>
                        <div className={"news-date"}>
                            {news.date}
                        </div>
                        <div className={"news-actions"} onMouseEnter={() => setDel(news.news_id)}>
                            <button className={"button-del"} onClick={DeleteNews}>Удалить</button>
                            <EditNewsForm news={news}/>
                        </div>
                    </div>
                );
            })}
            <AddNewsForm/>
        </div>
    );
}