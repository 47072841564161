import "./Categories.sass"
import {categoriesList} from "../../reducers/dataReducer";
import {Sechead} from "../../components/Sechead/Sechead";
import {useSelector} from "react-redux";
import {Link, useParams} from 'react-router-dom';
import React, {useState} from "react";
import {MainContacts} from "../../components/MainContacts/MainContacts";
import {Footer} from "../../components/Footer/Footer";
import {Helmet} from "react-helmet";

export function Categories() {
    let categories = useSelector(categoriesList);
    let currentCategory;
    const {category_name} = useParams();
    let fix_name = category_name.replaceAll("_", " ");
    for (let item of categories) {
        if (item.name === fix_name) {
            currentCategory = item;
        }
    }


    const [activeCategory, setCategory] = useState(currentCategory);


    return (
        <div className={"category-view"}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Возрождение жизни - {activeCategory.name}</title>
            </Helmet>
            <Sechead/>
            <div className={"category-view-page"}>
                <div className={"bread"}>
                    <ul>
                        <li><Link to="/">Главная</Link></li>
                        <li>{activeCategory.name}</li>
                    </ul>
                </div>
                <div className={"category-switch"}>
                    {categories.map((category) => {
                        if(category.id !== 6){
                        return (
                            <Link to={"/category/" + category.name.replaceAll(" ", '_')} key={category.id}>
                            <div className={category.id === activeCategory.id ? "category active" : "category"}
                                  onClick={() => {
                                setCategory(category);
                            }}>
                                <p><Link to={"/category/" + category.name.replaceAll(" ", '_')}>{category.name}</Link></p>
                            </div>
                                </Link>
                        )
                        }else {
                            return null;
                        }

                    })}
                </div>
                <h1>{activeCategory.name}</h1>
                <div className={"category-view-articles"}>
                    {activeCategory.articles.map((article) => {
                        return (
                            <Link to={"/article/" + article.header.replaceAll(" ", '_').replaceAll("?", "q")} key={article.article_id}>
                                <div className={"category-view-articles-item"}>
                                    <h4>{article.header}</h4>
                                    <div className={"category-view-articles-item-image"}>
                                        <div className={"frame"}><img
                                            src={"https://api.xn---24-eddkgafeegidb2egfb1b.xn--p1ai/upload/" + article.image_big}
                                            alt={article.header}/></div>
                                    </div>
                                    <div className={"category-view-articles-item-text"}>
                                        {article.description}
                                    </div>
                                </div>
                                <div className={"category-view-articles-item-link"}>
                                    читать
                                </div>
                            </Link>
                        )
                    })}
                </div>
            </div>
            <MainContacts/>
            <Footer/>
        </div>
    );
}