import {createSlice} from '@reduxjs/toolkit';

let doctors
let articles
let categories
let news
let price
let services
let spec_articles
let diseases
let images_gallery
let main_video
let video_categories
let reviews

const response = await fetch('https://api.xn---24-eddkgafeegidb2egfb1b.xn--p1ai/doctors/')
    .then((response) => {
        return response.json();
    })
    .then((data) => {
        doctors = data;
    });

const response2 = await fetch('https://api.xn---24-eddkgafeegidb2egfb1b.xn--p1ai/articles/')
    .then((response) => {
        return response.json();
    })
    .then((data) => {
        articles = data;
    });

const response3 = await fetch('https://api.xn---24-eddkgafeegidb2egfb1b.xn--p1ai/categories/')
    .then((response) => {
        return response.json();
    })
    .then((data) => {
        categories = data;
    });

const response4 = await fetch('https://api.xn---24-eddkgafeegidb2egfb1b.xn--p1ai/news/')
    .then((response) => {
        return response.json();
    })
    .then((data) => {
        news = data;
    });

const response5 = await fetch('https://api.xn---24-eddkgafeegidb2egfb1b.xn--p1ai/price/')
    .then((response) => {
        return response.json();
    })
    .then((data) => {
        price = data;
    });

const response6 = await fetch('https://api.xn---24-eddkgafeegidb2egfb1b.xn--p1ai/services/')
    .then((response) => {
        return response.json();
    })
    .then((data) => {
        services = data;
    });

const response7 = await fetch('https://api.xn---24-eddkgafeegidb2egfb1b.xn--p1ai/spec_articles/')
    .then((response) => {
        return response.json();
    })
    .then((data) => {
        spec_articles = data;
    });

const response8 = await fetch('https://api.xn---24-eddkgafeegidb2egfb1b.xn--p1ai/diseases/')
    .then((response) => {
        return response.json();
    })
    .then((data) => {
        diseases = data;
    });

const response9 = await fetch('https://api.xn---24-eddkgafeegidb2egfb1b.xn--p1ai/images-gallery/')
    .then((response) => {
        return response.json();
    })
    .then((data) => {
        images_gallery = data;
    });


let allSpeciality = ["Администрация", "Психиатр", "Детский психиатр", "Психотерапевт",
        "Клинический психолог", "Психолог", "Детский психолог", "Сексолог", "Диетолог", "Детский невролог",
        "Невролог", "Логопед", "Медиатор", "Профориентолог", "Cпециалист по соционике и эниостилю", "Младший медицинский персонал", "Клинер", "Все"];

let parallax_vis = true;


const response10 = await fetch('https://api.xn---24-eddkgafeegidb2egfb1b.xn--p1ai/main_video/')
    .then((response) => {
        return response.json();
    })
    .then((data) => {
        main_video = data;
    });


const response11 = await fetch('https://api.xn---24-eddkgafeegidb2egfb1b.xn--p1ai/video_categories/')
    .then((response) => {
        return response.json();
    })
    .then((data) => {
        video_categories = data;
    });


const response12 = await fetch('https://api.xn---24-eddkgafeegidb2egfb1b.xn--p1ai/reviews/all_published')
    .then((response) => {
        return response.json();
    })
    .then((data) => {
        reviews = data;
    });


const initialState = {
    doctors: doctors,
    articles: articles,
    categories: categories,
    news: news,
    price: price,
    services: services,
    spec_articles: spec_articles,
    diseases: diseases,
    images_gallery: images_gallery,
    profs: allSpeciality,
    parallax_vis: parallax_vis,
    intro_vis: true,
    main_video: main_video,
    video_categories: video_categories,
    reviews: reviews
}


export const dataSlice = createSlice({
    name: "dataSlice",
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        addDoctor: (state, action) => {
            state.doctors.push(action.payload);
        },
        deleteDoctor: (state, action) => {
            let x
            for (let i = 0; i < state.doctors.length; i++) {
                if(state.doctors[i].id === action.payload){
                    x = i;
                }
            }
            state.doctors.splice(x, 1);
        },
        editDoctor: (state, action) => {
            let item
            for (let i = 0; i < state.doctors.length; i++) {
                if(state.doctors[i].id === action.payload.id){
                    item = action.payload
                    for (let key in item){
                        state.doctors[i][key] = item[key];
                    }
                }
            }
        },


        addArticle: (state, action) => {
            state.articles.push(action.payload);
            for ( let i = 0; i < state.categories.length; i++){
                if(state.categories[i].id === action.payload.owner_id){
                    state.categories[i].articles.push(action.payload);
                }
            }
        },
        addCategory: (state, action) => {
            state.categories.push(action.payload);
        },
        deleteArticle: (state, action) => {
            let x
            for (let i = 0; i < state.articles.length; i++) {
                if(state.articles[i].article_id === action.payload){
                    x = i;
                }
            }
            state.articles.splice(x, 1);
        },
        editArticle: (state, action) => {
            let item
            for (let i = 0; i < state.articles.length; i++) {
                if(state.articles[i].article_id === action.payload.article_id){
                    item = action.payload
                    for (let key in item){
                        state.articles[i][key] = item[key];
                    }
                }
            }
        },


        addNews: (state, action) => {
            state.news.push(action.payload);
        },
        deleteNews: (state, action) => {
            let x
            for (let i = 0; i < state.news.length; i++) {
                if(state.news[i].news_id === action.payload){
                    x = i;
                }
            }
            state.news.splice(x, 1);
        },
        editNews: (state, action) => {
            let item
            for (let i = 0; i < state.news.length; i++) {
                if(state.news[i].news_id === action.payload.news_id){
                    item = action.payload
                    for (let key in item){
                        state.news[i][key] = item[key];
                    }
                }
            }
        },


        deletePriceItem: (state, action) => {
            let x
            let y
            let l
            for(let i = 0; i < state.price.length; i++){
                for (let j = 0; j < state.price[i].childrens.length; j++){
                    for (let z = 0; z < state.price[i].childrens[j].services.length; z++){
                        if (state.price[i].childrens[j].services[z].id === action.payload){
                            x = i;
                            y = j;
                            l = z;
                        }
                    }
                }
            }
            state.price[x].childrens[y].services.splice(l, 1);
        },
        addPriceItem: (state, action) => {
            for (let i = 0; i < state.price.length; i++){
                for (let j = 0; j < state.price[i].childrens.length; j++){
                    if (state.price[i].childrens[j].id === action.payload.section){
                        state.price[i].childrens[j].services.push(action.payload);
                    }
                }
            }
        },
        addPriceSection:(state, action) => {
            state.price.push(action.payload);
        },
        deletePriceSection:(state, action) => {
            let x;
            for (let i = 0; i < state.price.length; i++){
                if (state.price[i].id === action.payload){
                    x = i;
                }
            }
            if (x){
                state.price.splice(x, 1);
            }
        },
        editPriceSection:(state, action) => {
            let section;
            for (let i = 0; i < state.price.length; i++){
                if (state.price[i].id === action.payload.id){
                    section = action.payload
                    for (let key in section){
                        state.price[i][key] = section[key];
                    }
                }
            }
        },
        addPriceSubSection:(state, action) => {
            for (let i = 0; i < state.price.length; i++){
                    if (state.price[i].id === action.payload.parent){
                        state.price[i].childrens.push(action.payload);
                    }
            }
        },
        editPriceItem:(state, action) => {
            let item

            for(let i = 0; i < state.price.length; i++){
                for (let j = 0; j < state.price[i].childrens.length; j++){
                    for (let z = 0; z < state.price[i].childrens[j].services.length; z++){
                        if (state.price[i].childrens[j].services[z].id === action.payload.id){
                            item = action.payload
                            for (let key in item){
                                state.price[i].childrens[j].services[z][key] = item[key];
                            }
                            break;
                        }
                    }
                }
            }
        },

        changeVisParallax:(state) => {
            state.parallax_vis = false;
        },

        changeVisIntro:(state) => {
            state.intro_vis = false;
        },

        addService: (state, action) => {
            state.services.push(action.payload);
        },
        delService: (state, action) => {
            let x;
            for (let i = 0; i < state.services.length; i++){
                if(state.services[i].id === action.payload){
                    x = i;
                }
            }
            state.services.splice(x, 1);
        },
        editService: (state, action) => {
            let item
            for (let i = 0; i < state.services.length; i++) {
                if(state.services[i].id === action.payload.id){
                    item = action.payload
                    for (let key in item){
                        state.services[i][key] = item[key];
                    }
                }
            }
        },

        addSpecArticle: (state,action) => {
            state.spec_articles.push(action.payload);
            for ( let i = 0; i < state.services.length; i++){
                if(state.services[i].id === action.payload.owner_id){
                    state.services[i].spec_articles.push(action.payload);
                }
            }
        },
        delSpecArticle: (state, action) => {
            let x;
            for (let i = 0; i < state.spec_articles.length; i++){
                if(state.spec_articles[i].id === action.payload){
                    x = i;
                }
            }
            state.spec_articles.splice(x, 1);
            let y;
            let z;
            for ( let i = 0; i < state.services.length; i++){
                for(let j = 0; j< state.services[i].spec_articles.length; j++){
                    if (state.services[i].spec_articles[j].id === action.payload){
                        y = j;
                        z = i;
                        console.log(y, z);
                    }
                }
            }
            state.services[z].spec_articles.splice(y, 1);
        },
        editSpecArticle: (state, action) => {
            let item
            for (let i = 0; i < state.spec_articles.length; i++) {
                if(state.spec_articles[i].id === action.payload.id){
                    item = action.payload
                    for (let key in item){
                        state.spec_articles[i][key] = item[key];
                    }
                }
            }
        },

        addDisease: (state,action) => {
            state.diseases.push(action.payload);
            for ( let i = 0; i < state.services.length; i++){
                if(state.services[i].id === action.payload.owner_id){
                    state.services[i].diseases.push(action.payload);
                }
            }
        },
        delDisease: (state, action) => {
            let x;
            for (let i = 0; i < state.diseases.length; i++){
                if(state.diseases[i].id === action.payload){
                    x = i;
                }
            }
            state.diseases.splice(x, 1);
            let y;
            let z;
            for ( let i = 0; i < state.services.length; i++){
                for(let j = 0; j< state.services[i].diseases.length; j++){
                    if (state.services[i].diseases[j].id === action.payload){
                        y = j;
                        z = i;
                    }
                }
            }
            state.services[z].diseases.splice(y, 1);
        },
        editDisease: (state, action) => {
            let item
            for (let i = 0; i < state.diseases.length; i++) {
                if(state.diseases[i].id === action.payload.id){
                    item = action.payload
                    for (let key in item){
                        state.diseases[i][key] = item[key];
                    }
                }
            }
        },

        addImage: (state, action) => {
            state.images_gallery.push(action.payload);
        },
        DelImage: (state, action) => {
            let x
            for (let i = 0; i < state.images_gallery.length; i++) {
                if(state.images_gallery[i].id === action.payload){
                    x = i;
                }
            }
            state.images_gallery.splice(x, 1);
        },

        editMainVideo: (state, action) => {
            let item = action.payload;

            for (let key in item){
                state.main_video[key] = item[key];
            }
        },

        editVideoCategory: (state, action) => {
            let item;

            for (let i = 0; i < state.video_categories.length; i++) {
                if (state.video_categories[i].id === action.payload.id){
                    item = action.payload;
                    for (let key in item){
                        state.video_categories[i][key] = item[key];
                    }
                }
            }
        },

        addReview: (state, action) => {
            state.reviews.push(action.payload);
        },
        deleteReview: (state, action) => {
            let x;
            for (let i = 0; i < state.reviews.length; i++) {
                if(state.reviews[i].id === action.payload){
                    x = i;
                }
            }
            state.reviews.splice(x, 1);
        },
        editReview:(state, action) =>{
            let item
            for (let i = 0; i < state.reviews.length; i++) {
                if(state.reviews[i].id === action.payload.id){
                    item = action.payload
                    for (let key in item){
                        state.reviews[i][key] = item[key];
                    }
                }
            }
        }



    },
});

export const {addDoctor, addArticle,
    addCategory, addNews,
    deleteNews, editNews,
    deleteDoctor, editDoctor,
    deleteArticle, editArticle,
    deletePriceItem, addPriceItem,
    addPriceSection, changeVisParallax,
    addService, delService,
    editService, addSpecArticle,
    delSpecArticle, editSpecArticle,
    addDisease,delDisease,
    editDisease, changeVisIntro,
    addImage, DelImage,
    editMainVideo,editVideoCategory,
    addReview, deleteReview,
    editReview , editPriceItem,
    addPriceSubSection, deletePriceSection,
    editPriceSection} = dataSlice.actions;

export const doctorsList = (state) => state.dataSlice.doctors;


export const articlesList = (state) => state.dataSlice.articles;


export const categoriesList = (state) => state.dataSlice.categories;


export const newsList = (state) => state.dataSlice.news;


export const priceList = (state) => state.dataSlice.price;


export const servicesList = (state) => state.dataSlice.services;


export const specArticlesList = (state) => state.dataSlice.spec_articles;


export const diseasesList = (state) => state.dataSlice.diseases;


export const imagesGalleryList = (state) => state.dataSlice.images_gallery;


export const profList = (state) => state.dataSlice.profs;


export const visParallax = (state) => state.dataSlice.parallax_vis;


export const visIntro = (state) => state.dataSlice.intro_vis;


export const mainVideo = (state) => state.dataSlice.main_video;


export const videoCategoriesList = (state) => state.dataSlice.video_categories;


export const reviewsList = (state) => state.dataSlice.reviews;


export default dataSlice.reducer;
