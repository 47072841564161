import './Articles.sass';
import {useDispatch, useSelector} from "react-redux";
import {articlesList, categoriesList, deleteArticle} from "../../../../reducers/dataReducer";
import {AddArticleForm} from "./AddArticleForm";
import {useEffect, useState} from "react";
import {EditArticle} from "./EditArticle";

export function Articles() {
    let articles = useSelector(articlesList);
    let categories = useSelector(categoriesList);

    const dispatch = useDispatch();

    const [deletedNews, setDel] = useState(0);

    //токен
    const [token, setToken] = useState("");
    useEffect(() => {
        const token = JSON.parse(localStorage.getItem('token'));
        if (token) {
            setToken(token);
        }
    }, []);

    //__/токен

    function catPars(category) {
        let find
        for (let item of categories) {
            if (category === item.id) {
                find = item.name
            }
        }
        return find
    }

    let DeleteArticle = async (e) => {
        if(window.confirm('Удалить?')) {
            e.preventDefault();
            const requestOptions = {
                method: "DELETE",
                headers: {
                    "accept": "application/json",
                    Authorization: "Bearer " + token,
                },

            };
            const response = await fetch(`https://api.xn---24-eddkgafeegidb2egfb1b.xn--p1ai/articles/delete?article_id=${deletedNews}`, requestOptions);
            const data = await response.json();
            if (!response.ok) {
                console.log("Хуйня");
            } else {
                dispatch(deleteArticle(deletedNews));
                alert(data);
            }
        }
    };

    return (
        <div className="articles">
            {articles.map((article) => {
                return (
                    <div className={"article-item"} key={article.article_id}>
                        <div className={"article-header"}>
                            {article.header}
                        </div>
                        <div className={"article-datetime"}>
                            {article.date}
                        </div>
                        <div className={"article-description"}>
                            {article.text}
                        </div>
                        <div className={"article-category"}>
                            {catPars(article.owner_id)}
                        </div>
                         <div className={"article-author"}>
                            {article.author}
                        </div>
                        <div className={"article-actions"} onMouseEnter={() => setDel(article.article_id)}>
                            <button className={"button-del"} onClick={DeleteArticle}>Удалить</button>
                            <EditArticle article={article}/>
                        </div>
                    </div>
                );
            })}
            <AddArticleForm/>
        </div>
    );

}