import {useDispatch} from "react-redux";
import React, {useEffect, useState} from "react";
import {addPriceSubSection} from "../../../../reducers/dataReducer";
import validator from "validator";
import Modal from "react-modal";
export function AddPriceSubSection(props){
    let section = props.section;
    const dispatch = useDispatch();

    const [showModal, setModal] = useState(false);

    //токен
    const [token, setToken] = useState("");
    useEffect(() => {
        const token = JSON.parse(localStorage.getItem('token'));
        if (token) {
            setToken(token);
        }
    }, []);
    //__/токен

    const [subSectionName, setSubSectionName] = useState("");
    const [inputsValid, setValid] = useState({
        name: validator.isLength(subSectionName, {min: 5, max: 40}),
    });

    const formValid = (values) => {
        return !(values.name === true);
    };

    let AddNewSubSection = async (e) => {
        e.preventDefault();
        const requestOptions = {
            method: "POST",
            headers: {
                "accept": "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
            body: JSON.stringify({
                "id": 0,
                "name": subSectionName,
                "parent": section.id.toString(),
                "services": [],
            }),
        };
        const response = await fetch("https://api.xn---24-eddkgafeegidb2egfb1b.xn--p1ai/price/add_subsection", requestOptions);
        const data = await response.json();
        if (!response.ok) {
            console.log("Хуйня");
        } else {
            dispatch(addPriceSubSection(data));
        }
        setModal(false);
        setSubSectionName("");
    };

    return(
        <div className={"add-price-subsection"}>
            <div className={"add-price-subsection-item"}>
                <button onClick={() => setModal(true)} className={"add-price-item-button"}>Добавить</button>
            </div>
            <Modal isOpen={showModal} contentLabel="Форма: Добавить Подраздел">
                <h2>Добавить подраздел</h2>
                <form>
                    <label>
                        <span>Название</span>
                        <input
                            name="price-subsection-item"
                            type="text"
                            value={subSectionName}
                            onChange={(event) => setSubSectionName(event.target.value)}
                            onInput={(event) =>
                                setValid({
                                    ...inputsValid,
                                    name: validator.isLength(event.target.value, {
                                        min: 5,
                                        max: 120,
                                    }),
                                })
                            }
                        />
                        <span className="warning">
                              {inputsValid.name === true
                                  ? ""
                                  : "Введите название!"}
                            </span>
                    </label>
                    <br/>
                </form>
                <button
                    onClick={AddNewSubSection}
                    className="AddButton"
                    disabled={formValid(inputsValid)}
                >
                    Добавить
                </button>
                <button onClick={() => setModal(false)} className="closeButton">
                    Закрыть
                </button>
            </Modal>
        </div>
    );
}