import {useDispatch} from "react-redux";
import React, {useEffect, useState} from "react";
import Modal from "react-modal";
import validator from "validator";
import {editPriceSection} from "../../../../reducers/dataReducer";


export function EditPriceSection(props){
    let edited_section = props.section;

    const dispatch = useDispatch();
    const [showModal, setModal] = useState(false);

    const [sectionId, setId] = useState(edited_section.id);
    const [sectionName, setName] = useState(edited_section.parent_name);

    //токен
    const [token, setToken] = useState("");
    useEffect(() => {
        const token = JSON.parse(localStorage.getItem('token'));
        if (token) {
            setToken(token);
        }
    }, []);
    //__/токен

    let EditPriceSection = async (e) => {
        e.preventDefault();
        const requestOptions = {
            method: "PATCH",
            headers: {
                "accept": "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
            body: JSON.stringify({
                "parent_name": sectionName,
            }),
        };
        const response = await fetch(`https://api.xn---24-eddkgafeegidb2egfb1b.xn--p1ai/price/edit_price_section?price_section_id=${sectionId}`, requestOptions);
        const data = await response.json();
        if (!response.ok) {
            console.log("Хуйня");
        } else {
            dispatch(editPriceSection(data));
        }
        setModal(false);
    };

    return(
        <div className={"button-edit-price-section"}>
            <button onClick={() => setModal(true)} className={"edit-section"}>Изменить</button>
            <Modal isOpen={showModal} contentLabel="Форма: изменить">
                <h2>Редактировать раздел</h2>
                <form>
                    <label>
                        <span>Название Раздела</span>
                        <input
                            name="price-section-name"
                            type="text"
                            value={sectionName}
                            onChange={(event) => setName(event.target.value)}

                        />

                    </label>
                    <br/>
                    <button
                        onClick={EditPriceSection}
                        className="editButton"
                    >
                        Изменить
                    </button>
                </form>
            </Modal>
        </div>
    );
}