import {useDispatch} from "react-redux";
import React, {useEffect, useState} from "react";
import validator from "validator";
import {addPriceSection} from "../../../../reducers/dataReducer";
import "./AddPriceSection.sass"


export function AddPriceSection() {
    const dispatch = useDispatch();

    //токен
    const [token, setToken] = useState("");
    useEffect(() => {
        const token = JSON.parse(localStorage.getItem('token'));
        if (token) {
            setToken(token);
        }
    }, []);
    //__/токен


    const [sectionName, setName] = useState("");
    const [inputsValid, setValid] = useState({
        name: validator.isLength(sectionName, {min: 5, max: 40}),
    });

    const formValid = (values) => {
        return !(values.name === true);
    };
    const [formVis, setVis] = useState(false)


    let AddNewSection = async (e) => {
        e.preventDefault();
        const requestOptions = {
            method: "POST",
            headers: {
                "accept": "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
            body: JSON.stringify({
                "id": 0,
                "parent_name": sectionName,
                "childrens": []
            }),
        };
        const response = await fetch("https://api.xn---24-eddkgafeegidb2egfb1b.xn--p1ai/price/add_section", requestOptions);
        const data = await response.json();
        if (!response.ok) {
            console.log("Хуйня");
        } else {
            dispatch(addPriceSection(data));
        }
        setVis(false);
        setName("");
    };

    return (
        <div className={"add-price-section"}>
            <button onClick={() => setVis(true)}>+ Новый раздел</button>
            <div className={!formVis ? "add-price-section-form collapsed" : "add-price-section-form expanded"}>
                <form>
                    <label>
                        <span>Название Раздела</span>
                        <input
                            name="price-section-name"
                            type="text"
                            value={sectionName}
                            onChange={(event) => setName(event.target.value)}
                            onInput={(event) =>
                                setValid({
                                    ...inputsValid,
                                    name: validator.isLength(event.target.value, {
                                        min: 5,
                                        max: 40,
                                    }),
                                })
                            }
                        />
                        <span className="warning">
                              {inputsValid.name === true
                                  ? ""
                                  : "Введите Название!"}
                            </span>
                    </label>
                </form>
                <button
                    onClick={AddNewSection}
                    className="AddButton"
                    disabled={formValid(inputsValid)}
                >
                    Добавить
                </button>
                <button onClick={() => setVis(false)} className="closeButton">
                    Отмена
                </button>
            </div>
        </div>
    );
}