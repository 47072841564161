import {IoCloseOutline, IoMenu} from "react-icons/io5";
import React, {useState} from "react";
import {Link} from "react-router-dom";
import "./MobileMenu.sass";
import 'animate.css';
import {ServisesMenuMobile} from "./ServisesMenuMobile";
import {HashLink} from "react-router-hash-link";
import {Link as Link1} from "react-scroll";
import {FaLongArrowAltDown, FaLongArrowAltUp} from "react-icons/fa";

export function MobileMenu(){
    const [menuStatus, setMenustatus] = useState(false);
    const [aboutStatus, setAbout] = useState(false);
    const [servisesStatus, setServ] = useState(false);
    const [blogStatus, setBlog] = useState(false);
    const [moreStatus, setMore] = useState(false);

    const scrollWithOffset = (el) => {
        const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
        const yOffset = -150;
        window.scrollTo({top: yCoordinate + yOffset, behavior: 'smooth'});
    }

    return(
        <div className={"mobile-menu"}>
            <div className={"menu-close-button"} onClick={() => setMenustatus(!menuStatus)}>
                {menuStatus ? <IoCloseOutline/> : <IoMenu/>}
            </div>
            <div className={ menuStatus ? "menu-mobile-expanded animate__animated animate__slideInDown animate__faster": "menu-mobile-collapsed"}>
                <div className={"mobile-menu-block"}>
                    <ul>
                        <li><span onClick={() => setAbout(!aboutStatus)}>О центре</span>{aboutStatus ? <FaLongArrowAltUp /> : <FaLongArrowAltDown />}
                            <div className={aboutStatus ? "sub-menu-vis" : "sub-menu-hide"}>
                                <ul>
                                    <li><Link to={"/article/О_центре"} onClick={() => setMenustatus(false)}>О центре</Link></li>
                                    <li><Link to={"/article/Преимущества"} onClick={() => setMenustatus(false)}>Преимущества</Link></li>
                                    <li><Link to={"/feedback"} onClick={() => setMenustatus(false)}>Отзывы</Link></li>
                                    <li><Link to={"/gallery"} onClick={() => setMenustatus(false)}>Галерея</Link></li>
                                    <li><Link to={"/licenses"} onClick={() => setMenustatus(false)}>Лицензии</Link></li>
                                    <li><Link to={"/category/Направления_работы"} onClick={() => setMenustatus(false)}>Направления работы</Link></li>
                                </ul>
                            </div>
                        </li>
                        <li>
                            <Link to="/specs" >
                                Специалисты
                            </Link>
                        </li>
                        <li ><span onClick={() => setServ(!servisesStatus)}>Услуги</span>{servisesStatus ? <FaLongArrowAltUp /> : <FaLongArrowAltDown />}
                            <div className={servisesStatus ? "sub-menu-vis" : "sub-menu-hide"}>
                                <ServisesMenuMobile menustatus={setMenustatus}/>
                            </div>
                        </li>
                        <li>
                            <HashLink to="/#price" smooth scroll={scrollWithOffset} onClick={() => setMenustatus(false)}>
                                    Цены
                            </HashLink>
                        </li>
                        <li><span onClick={() => setBlog(!blogStatus)}>Блог</span>{blogStatus ? <FaLongArrowAltUp /> : <FaLongArrowAltDown />}
                            <div className={blogStatus ? "sub-menu-vis" : "sub-menu-hide"}>
                                <ul>
                                    <li><Link to={"/video"} onClick={() => setMenustatus(false)}>Видео</Link></li>
                                    <li><Link to={"/articles"} onClick={() => setMenustatus(false)}>Статьи</Link></li>
                                </ul>
                            </div>
                        </li>
                        <li>
                            <Link1 to="vr-tech" offset={-150} smooth={true} onClick={() => setMenustatus(false)}>
                                    VR технологии
                            </Link1>
                        </li>
                        <li>
                            <Link to="/news" onClick={() => setMenustatus(false)} >
                                    Новости
                            </Link>
                        </li>
                        <li>
                            <Link1 to="contacts" offset={-150} smooth={true} onClick={() => setMenustatus(false)}>
                                    Контакты
                            </Link1>
                        </li>
                        <li><span onClick={() => setMore(!moreStatus)}>Еще</span>{moreStatus ? <FaLongArrowAltUp /> : <FaLongArrowAltDown />}
                            <div className={moreStatus ? "sub-menu-vis" : "sub-menu-hide"}>
                                <ul>
                                    <li><Link to={"/feedback"} onClick={() => setMenustatus(false)}>Отзывы</Link></li>
                                    <li><Link to={"/documents"} onClick={() => setMenustatus(false)}>Документы</Link></li>
                                    <li><Link to={"/regulatory"} onClick={() => setMenustatus(false)}>Контролирующие органы</Link></li>
                                    <li><Link to={"/vacancies"} onClick={() => setMenustatus(false)}>Вакансии</Link></li>
                                </ul>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}