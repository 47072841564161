import {delDisease, delService, delSpecArticle, servicesList} from "../../../../reducers/dataReducer";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import "./Services.sass"
import {AddService} from "./AddService";
import {IoTrashOutline } from "react-icons/io5";
import {AddSpecArticle} from "./AddSpecArticle";
import {EditService} from "./EditService";
import {EditSpecArticle} from "./EditSpecArticle";
import {AddDisease} from "./AddDiseases";
import {EditDisease} from "./EditDisease";

export function Services() {
    const dispatch = useDispatch();
    let services = useSelector(servicesList);

    const [activeSection, setSection] = useState(services[0]);

    const [deletedService, setDelService] = useState(0);
    const [deletedSpecArticle, setDelSpec] = useState(0);
    const [deletedDisease, setDelDisease] = useState(0);

    //токен
    const [token, setToken] = useState("");
    useEffect(() => {
        const token = JSON.parse(localStorage.getItem('token'));
        if (token) {
            setToken(token);
        }
    }, []);

    //__/токен
    let DeleteService = async (e) => {
        if(window.confirm('Удалить?')) {
            e.preventDefault();
            const requestOptions = {
                method: "DELETE",
                headers: {
                    "accept": "application/json",
                    Authorization: "Bearer " + token,
                },

            };
            const response = await fetch(`https://api.xn---24-eddkgafeegidb2egfb1b.xn--p1ai/services/delete?service_id=${deletedService}`, requestOptions);
            const data = await response.json();
            if (!response.ok) {
                console.log("Хуйня");
            } else {
                dispatch(delService(deletedService));
                alert(data);
            }
        }
    };

    let DeleteSpecArticle = async (e) => {
        if(window.confirm('Удалить?')) {
            e.preventDefault();
            const requestOptions = {
                method: "DELETE",
                headers: {
                    "accept": "application/json",
                    Authorization: "Bearer " + token,
                },

            };
            const response = await fetch(`https://api.xn---24-eddkgafeegidb2egfb1b.xn--p1ai/spec_articles/delete?spec_article_id=${deletedSpecArticle}`, requestOptions);
            const data = await response.json();
            if (!response.ok) {
                console.log("Хуйня");
            } else {
                dispatch(delSpecArticle(deletedSpecArticle));
                alert(data);
            }
        }
    };

    let DeleteDisease = async (e) => {
        if(window.confirm('Удалить?')) {
            e.preventDefault();
            const requestOptions = {
                method: "DELETE",
                headers: {
                    "accept": "application/json",
                    Authorization: "Bearer " + token,
                },

            };
            const response = await fetch(`https://api.xn---24-eddkgafeegidb2egfb1b.xn--p1ai/diseases/delete?diseases_id=${deletedDisease}`, requestOptions);
            const data = await response.json();
            if (!response.ok) {
                console.log("Хуйня");
            } else {
                dispatch(delDisease(deletedDisease));
                alert(data);
            }
        }
    }



    return (
        <div className={"admin-services"}>
            <div className={"left-column"}>
                {services.map((service) => {
                    return (
                        <div key={service.id} onClick={() => setSection(service)} className={"left-column-service"}>
                            <div className={"left-column-service-name"}>{service.name}</div>
                            <div className={"left-column-service-buttons"} onMouseEnter={() => setDelService(service.id)}>
                                <EditService service={service} />
                                <button onClick={DeleteService}><IoTrashOutline/></button>
                            </div>
                        </div>
                    )
                })}
                <AddService/>
            </div>
            <div className={"right-column"}>
                <div className={"column-1"}>
                    <h3>Специалисты</h3>
                    {activeSection.spec_articles.map((item) => {
                        return (
                            <div key={item.id}>
                                <div className={"right-column-spec-name"}>{item.name}</div>
                                <div className={"right-column-spec-buttons"} onMouseEnter={() => setDelSpec(item.id)}>
                                    <EditSpecArticle spec_article={item}/>
                                    <button onClick={DeleteSpecArticle}><IoTrashOutline/></button>
                                </div>
                            </div>
                        )
                    })}
                    <AddSpecArticle/>
                </div>
                <div className={"column-2"}>
                    <h3>Заболевания</h3>
                    {activeSection.diseases.map((item) => {
                        return (
                            <div key={item.id}>
                                <div className={"right-column-disease-name"}>{item.name}</div>
                                <div className={"right-column-disease-buttons"} onMouseEnter={() => setDelDisease(item.id)}>
                                    <EditDisease disease={item}/>
                                    <button onClick={DeleteDisease}><IoTrashOutline/></button>
                                </div>
                            </div>
                        )
                    })}
                    <AddDisease/>
                </div>
            </div>
        </div>
    );
}